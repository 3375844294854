/**
 * Normalize.css and sTailwind's base styles.
 * https://create-react-app.dev/docs/adding-css-reset/
 */
@import-normalize;

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;1,600&display=swap');

@import 'tailwindcss/base';

/**
 * Custom base styles, applied after the tailwind-base classes
 */
html {
    font-size: 87.5%;
    -webkit-font-smoothing: antialiased;
}
html, body, #root {
    position: relative;
    margin: 0;
    min-height: 100vh;
}